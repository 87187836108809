import { MainNavigationMenu, IconThreat } from 'ui';
import GridViewIcon from '@mui/icons-material/GridView';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { ROUTES } from 'routes/routes.const';

const Navigation = () => {
  const navigationOptions = [
    {
      title: 'Dashboard',
      url: ROUTES.DASHBOARD,
      icon: <GridViewIcon key="admin" />,
    },
    {
      title: 'Users',
      url: ROUTES.USERS,
      icon: <PersonOutlineOutlinedIcon key="users" />,
    },
    {
      title: 'Security Group',
      url: ROUTES.SECURITY_GROUP,
      icon: <VerifiedUserOutlinedIcon key="securityGroup" />,
    },
    {
      title: 'Security Endpoints',
      url: ROUTES.SECURITY_ENDPOINTS,
      icon: <KeyOutlinedIcon key="security" />,
    },
    {
      title: 'Services',
      url: ROUTES.SERVICES,
      icon: <LayersOutlinedIcon key="services" />,
    },
    {
      title: 'Network',
      url: ROUTES.NETWORK,
      icon: <LanguageOutlinedIcon key="network" />,
    },
    {
      title: 'Organization',
      url: ROUTES.ORGANIZATION,
      icon: <BusinessCenterOutlinedIcon key="organization" />,
    },
    {
      title: 'Threat Manager',
      url: ROUTES.THREAT_MANAGER,
      icon: <IconThreat key="Threat" />,
    },
  ];

  return <MainNavigationMenu options={navigationOptions} />;
};

export default Navigation;
